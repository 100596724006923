import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

// Bind modal to your app element
Modal.setAppElement('#root');

const RegisterForm = () => {
  // Define initial form data
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    startDate: new Date(),
    endDate: new Date(new Date().setDate(new Date().getDate() + 1)), // Set end date to tomorrow
    startTime: '00:00',
    endTime: '00:00',
    PID: '',
    lifetimeValidity: false
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [tokenPID, setTokenPID] = useState('');
  const [dateError, setDateError] = useState('');
  const [modalContent, setModalContent] = useState({
    isOpen: false,
    message: '',
    email: '',
    residentEmail: '',
    residentFirstName: '',
    firstName: ''
  });

  useEffect(() => {
    const setDefaultDates = () => {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      setFormData(prevState => ({
        ...prevState,
        startDate: today,
        endDate: tomorrow
      }));
    };

    const fetchProperties = async () => {
      try {
        const token = Cookies.get('token');

        if (!token) {
          return;
        }

        const decodedToken = jwtDecode(token);
        setIsSuperAdmin(decodedToken.role === 'superadmin');
        setTokenPID(decodedToken.PID || '');

        if (decodedToken.role === 'superadmin') {
          const response = await axios.get(`/api/property/list-user-properties`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          setProperties(response.data.properties);
        }

        setDefaultDates();
      } catch (error) {
        console.error('Error fetching properties or decoding token:', error);
      }
    };

    fetchProperties();
  }, []);

  const validateDates = (startDate, startTime, endDate, endTime) => {
    const start = new Date(`${format(startDate, 'yyyy-MM-dd')}T${startTime}`);
    const end = new Date(`${format(endDate, 'yyyy-MM-dd')}T${endTime}`);

    if (start > end) {
      setDateError('End Date and Time cannot be earlier than Start Date and Time');
      return false;
    }
    setDateError('');
    return true;
  };


  const handleChangeDate = (date, name) => {
    setFormData(prevState => {
      const updatedData = { ...prevState, [name]: date };

      if (name === 'startDate' || name === 'endDate') {
        validateDates(updatedData.startDate, updatedData.startTime, updatedData.endDate, updatedData.endTime);
      }

      return updatedData;
    });
  };

  const highlightText = (text, keywords) => {
    const regex = new RegExp(`(${keywords.join('|')})`, 'gi');
    return text.split(regex).map((part, index) =>
      keywords.some(keyword => keyword.toLowerCase() === part.toLowerCase()) ? (
        <span key={index} style={{ color: 'blue' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleChange = e => {
    const { name, value, type, checked } = e.target;

    if (name === 'lifetimeValidity') {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 365 * 100);

      setFormData(prevState => ({
        ...prevState,
        lifetimeValidity: checked,
        startDate: checked ? today : prevState.startDate,
        startTime: checked ? '00:00' : prevState.startTime,
        endDate: checked ? tomorrow : prevState.endDate,
        endTime: checked ? '00:00' : prevState.endTime
      }));
    } else {
      setFormData(prevState => {
        const updatedData = { ...prevState, [name]: type === 'checkbox' ? checked : value };

        if (['startDate', 'endDate', 'startTime', 'endTime'].includes(name)) {
          validateDates(updatedData.startDate, updatedData.startTime, updatedData.endDate, updatedData.endTime);
        }

        return updatedData;
      });
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateDates(formData.startDate, formData.startTime, formData.endDate, formData.endTime)) {
      setIsLoading(false);
      return;
    }

    const token = Cookies.get('token');

    if (!token) {
      setIsLoading(false);
      return;
    }

    const decodedToken = jwtDecode(token);
    const UID = decodedToken._id;

    const startDateTime = `${format(formData.startDate, 'yyyy-MM-dd')}T${formData.startTime}`;
    const endDateTime = `${format(formData.endDate, 'yyyy-MM-dd')}T${formData.endTime}`;

    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await axios.post(
        `/api/visitor/register`,
        {
          ...formData,
          startDate: startDateTime,
          endDate: endDateTime,
          timezone: userTimezone,
          PID: formData.PID || tokenPID,
          UID
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const { residentEmail, residentFirstName, firstName, email } = response.data;

      setModalContent({
        isOpen: true,
        message: `${residentFirstName}, The system generated a QR Code and it has been emailed to ${firstName}'s email (${email}) and your email address (${residentEmail}) as well.`,
        residentEmail,
        residentFirstName,
        firstName,
        email
      });

      // Reset form and other states
      setFormData(initialFormData);
      setDateError('');
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto py-4 px-0 sm:p-4">
      <form
        onSubmit={handleSubmit}
        className="bg-slate-100 border p-8 rounded-lg shadow-md max-w-lg mx-auto"
        autoComplete="off"
      >
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-center mb-6">Register Visitor</h2>
          <label htmlFor="firstName" className="block text-gray-700 text-sm font-semibold mb-2">First Name</label>
          <input
            id="firstName"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter the visitor's first name"
            autoComplete="new-first-name"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="lastName" className="block text-gray-700 text-sm font-semibold mb-2">Last Name</label>
          <input
            id="lastName"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter the visitor's last name"
            autoComplete="new-last-name"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 text-sm font-semibold mb-2">Email</label>
          <input
            id="email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter the visitor's email address"
            autoComplete="new-email"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          />
        </div>

        <div className="mb-4">
          <input
            id="lifetimeValidity"
            type="checkbox"
            name="lifetimeValidity"
            checked={formData.lifetimeValidity}
            onChange={handleChange}
            className="mr-2"
          />
          <span className="text-gray-700 text-sm font-semibold mb-2">Check for Permanent Visitor</span>
        </div>

        <div className="mb-4 flex flex-row sm:items-center lg:gap-4 gap-1">
          <div className="flex-1">
            <label htmlFor="startDate" className="block text-gray-700 text-sm font-semibold mb-2">Start Date</label>
            <DatePicker
              id="startDate"
              selected={formData.startDate}
              onChange={date => handleChangeDate(date, 'startDate')}
              dateFormat="MM/dd/yyyy"
              className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
            />
          </div>

          <div className="flex-1">
            <label htmlFor="startTime" className="block text-gray-700 text-sm font-semibold mb-2">Start Time</label>
            <input
              id="startTime"
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
              autoComplete="off"
              required
              className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
            />
          </div>
        </div>

        {!formData.lifetimeValidity && (
          <>
            <div className="mb-4 flex flex-row sm:items-center lg:gap-4 gap-1">
              <div className="flex-1">
                <label htmlFor="endDate" className="block text-gray-700 text-sm font-semibold mb-2">End Date</label>
                <DatePicker
                  id="endDate"
                  selected={formData.endDate}
                  onChange={date => handleChangeDate(date, 'endDate')}
                  dateFormat="MM/dd/yyyy"
                  className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                />
              </div>

              <div className="flex-1">
                <label htmlFor="endTime" className="block text-gray-700 text-sm font-semibold mb-2">End Time</label>
                <input
                  id="endTime"
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  autoComplete="off"
                  required
                  className="w-full px-2 h-10 sm:px-3 py-2 border border-gray-300 rounded-lg"
                />
              </div>
            </div>
            {dateError && <p className="text-red-700 text-sm mb-4 rounded px-2 py-2 bg-red-200 w-full">{dateError}</p>}
          </>
        )}

        {isSuperAdmin && (
          <div className="mb-4">
            <label htmlFor="PID" className="block text-gray-700 text-sm font-semibold mb-2">Property</label>
            <select
              id="PID"
              name="PID"
              value={formData.PID || ''}
              onChange={handleChange}
              placeholder="Select a Property"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            >
              <option value="">Select a Property</option>
              {properties.map(property => (
                <option key={property._id} value={property._id}>
                  {`${property.propertyName} (${property.propertyLocation})`}
                </option>
              ))}
            </select>
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={isLoading || dateError}
        >
          {isLoading ? 'Submitting...' : 'Submit'}
        </button>
      </form>

      <Modal
        isOpen={modalContent.isOpen}
        onRequestClose={() => setModalContent({ ...modalContent, isOpen: false })}
        className="fixed inset-0 flex items-center justify-center p-4"
        overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-sm">
          <h2 className="text-lg font-semibold text-green-800">Registration Successful!</h2>
          <p className="mt-2">
            {highlightText(modalContent.message, [
              modalContent.email,
              modalContent.residentEmail
            ])}
          </p>
          <button
            onClick={() => setModalContent({ ...modalContent, isOpen: false })}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterForm;
