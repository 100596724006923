import React from 'react';
import Login from '../login/Login';
import SuperAdminLogin from '../login/SuperAdminLogin';

const LoginPage = () => {
  // Extract the type and propertyName from the URL
  const url = window.location.href;
  const urlParts = url.split('/');
  let type = urlParts[urlParts.length - 1];
  if (type === '') {
    type = urlParts[urlParts.length - 2];
  }

  // Normalize the type to lowercase for case-insensitive comparison
  const normalizedType = type.toLowerCase();

  return (
    <div className="container mx-auto mt-2">
      {(normalizedType === 'resident-login') && <Login />}
      {(normalizedType === 'property-manager-login') && <Login />}
      {(normalizedType === 'super-admin') && <SuperAdminLogin />}
      {(!normalizedType || !['resident-login', 'property-manager-login', 'super-admin'].includes(normalizedType)) && (
        <Login />
      )}
    </div>
  );
};

export default LoginPage;
