import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userType, setUserType] = useState('resident');
  const { isAuthenticated, login, role } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const url = window.location.href;
    const urlParts = url.split('/');
    let type = urlParts[urlParts.length - 1];
    if (type === '') {
      type = urlParts[urlParts.length - 2];
    }
    if (type === ('Property-Manager-Login' || 'property-manager-login')) {
      setUserType('admin');
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if(role === 'admin') {
        navigate('/register-user');
      }
      if(role ==='resident') {
        navigate('/');
      }
    }
  }, [isAuthenticated, role, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const loginData = { email, password, userType };
      const response = await axios.post(`/api/resident/login`, loginData, {
        headers: { 'Content-Type': 'application/json' },
      });
      // console.log('Login successful:', response.data);
      login(response.data.token);
      if(role === 'admin') {
        window.location.href = '/register-user';
      }
      if(role ==='resident') {
        window.location.href = '/';
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
    }
  };

  return (
    <div className="mx-auto max-w-lg">
      <div className="bg-slate-100 p-8 rounded-lg shadow-lg w-full border">
        <h2 className="text-2xl font-bold text-center mb-6">
        {userType === 'admin' ? 'Property Manager' : 'Resident'} Login
        </h2>
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <div className="mb-4 relative">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 px-3 mt-3 py-2 text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserLogin;
