import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const formatDate = (date) => {
    return new Date(date).toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });
};

const VisitorsList = () => {
    const [visitors, setVisitors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editedQrCode, setEditedQrCode] = useState(null);
    const [validationError, setValidationError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [successMessageType, setSuccessMessageType] = useState(''); // To distinguish types (e.g., 'delete' or 'update')
    const [confirmationPopup, setConfirmationPopup] = useState(null); // For delete confirmation popup

    const token = Cookies.get('token');

    useEffect(() => {
        const fetchVisitors = async () => {
            try {
                const response = await axios.get(`/api/visitor/visitors-qr`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setVisitors(response.data);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch visitors');
                setLoading(false);
            }
        };

        fetchVisitors();
    }, [token]);

    const validateDates = useCallback(() => {
        if (!editedQrCode) return;

        const { startDate, endDate } = editedQrCode;

        if (startDate && endDate) {
            if (startDate >= endDate) {
                setValidationError('End date must be after start date.');
            } else {
                setValidationError('');
            }
        } else {
            setValidationError('');
        }
    }, [editedQrCode]);

    useEffect(() => {
        validateDates();
    }, [editedQrCode, validateDates]);

    const handleEditQrCode = (visitorId, qrCode) => {
        setEditedQrCode({
            visitorId,
            _id: qrCode._id,
            startDate: new Date(qrCode.startDate),
            endDate: qrCode.lifetimeValidity ? null : new Date(qrCode.endDate),
            lifetimeValidity: qrCode.lifetimeValidity,
        });
        setValidationError('');
    };

    const handleDateChange = (field, date) => {
        setEditedQrCode(prevState => ({
            ...prevState,
            [field]: date
        }));
    };

    const handleLifetimeValidityChange = (checked) => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0); // Set to 12 AM

        setEditedQrCode(prevState => ({
            ...prevState,
            lifetimeValidity: checked,
            startDate: checked ? prevState.startDate : new Date(today.setHours(0, 0, 0, 0)), // Set start date to today at 12 AM
            endDate: checked ? null : tomorrow // Set end date to tomorrow at 12 AM
        }));
    };

    // Function to show a success message with a timeout
    const showSuccessMessage = (message, type = '') => {
        setSuccessMessage(message);
        setSuccessMessageType(type);
        setTimeout(() => {
            setSuccessMessage('');
            setSuccessMessageType('');
        }, 2000);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validationError) return;

        try {
            const startDateUTC = editedQrCode.startDate.toISOString();
            const endDateUTC = editedQrCode.lifetimeValidity
                ? new Date(editedQrCode.startDate).setFullYear(new Date().getFullYear() + 100)
                : editedQrCode.endDate.toISOString();

            await axios.put(`/api/visitor/visitors-qr/${editedQrCode.visitorId}/update-qrcodes`, {
                _id: editedQrCode._id,
                startDate: startDateUTC,
                endDate: endDateUTC,
                lifetimeValidity: editedQrCode.lifetimeValidity,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const response = await axios.get(`/api/visitor/visitors-qr`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setVisitors(response.data);
            setEditedQrCode(null);
            showSuccessMessage('QR code updated successfully.', 'update');
        } catch (err) {
            setError(`Failed to update QR code: ${err.response ? err.response.data.message : err.message}`);
        }
    };

    const expireQRCode = async (visitorId, qrCodeId) => {
        try {
            await axios.post(
                `/api/visitor/expire-qrcode`,
                { qrCodeId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            const response = await axios.get(`/api/visitor/visitors-qr`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setVisitors(response.data);
            showSuccessMessage('QR code deleted successfully.', 'delete');
        } catch (err) {
            setError('Failed to expire QR code');
        }
    };

    const confirmDelete = (visitorId, qrCodeId) => {
        setConfirmationPopup({ visitorId, qrCodeId });
    };

    const handleCancelDelete = () => {
        setConfirmationPopup(null);
    };

    if (loading) return <div className="text-center text-gray-700">Loading...</div>;
    if (error) return <div className="text-center text-red-500">{error}</div>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-4xl font-bold mb-8 text-gray-800">Visitor List</h1>
            {visitors.length === 0 ? (
                <p className="text-center text-gray-600">No visitors found</p>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {visitors.map((visitor) => (
                        <div key={visitor._id} className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
                            <p className="text-xl font-semibold text-gray-700">Email: {visitor.email}</p>
                            <div className="mt-6">
                                <h3 className="text-lg font-medium text-gray-800">QR Code Valid Times:</h3>
                                {visitor.qrCodes.map((qr) => (
                                    <div key={qr._id} className="mt-4 border-t border-gray-200 pt-4">
                                        <h2 className="text-xl font-semibold text-gray-800">{qr.qrFirstName} {qr.qrLastName}</h2>
                                        <p className="text-gray-600">Start Date: {formatDate(qr.startDate)}</p>
                                        <p className="text-gray-600">End Date: {qr.lifetimeValidity ? 'Permanent Visitor' : formatDate(qr.endDate)}</p>
                                        <p className="text-gray-600">Created At: {formatDate(qr.createdAt)}</p>
                                        <button
                                            onClick={() => handleEditQrCode(visitor._id, qr)}
                                            className="mt-3 me-1 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200">
                                            Edit Visitor Time
                                        </button>
                                        <button
                                            onClick={() => confirmDelete(visitor._id, qr._id)}
                                            className="mt-3 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200">
                                            Delete QR Code
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {editedQrCode && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg">
                        <h2 className="text-2xl font-bold text-gray-800 mb-4">Edit QR Code</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">Start Date:</label>
                                <DatePicker
                                    selected={editedQrCode.startDate}
                                    onChange={(date) => handleDateChange('startDate', date)}
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    showTimeSelect
                                    dateFormat="Pp"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-medium mb-2">End Date:</label>
                                <DatePicker
                                    selected={editedQrCode.endDate}
                                    onChange={(date) => handleDateChange('endDate', date)}
                                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    showTimeSelect
                                    dateFormat="Pp"
                                    disabled={editedQrCode.lifetimeValidity}
                                />
                            </div>
                            <div className="mb-4">
                                <label className="flex items-center text-gray-700 font-medium">
                                    <input
                                        type="checkbox"
                                        checked={editedQrCode.lifetimeValidity}
                                        onChange={(e) => handleLifetimeValidityChange(e.target.checked)}
                                        className="mr-2"
                                    />
                                    Permanent Visitor
                                </label>
                            </div>
                            {validationError && <p className="text-red-700 text-sm mb-4 rounded px-2 py-2 bg-red-200 w-full lg:w-1/2">{validationError}</p>}
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setEditedQrCode(null)}
                                    className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-200 mr-2">
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-200"
                                    disabled={!!validationError}>
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {confirmationPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
                        <h2 className="text-xl font-bold text-gray-800 mb-4">Confirm Deletion</h2>
                        <p className="text-gray-700 mb-6">Are you sure you want to delete this QR code?</p>
                        <div className="flex justify-end">
                            <button
                                onClick={handleCancelDelete}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-200 mr-2">
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    expireQRCode(confirmationPopup.visitorId, confirmationPopup.qrCodeId);
                                    handleCancelDelete();
                                }}
                                className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-200">
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {successMessage && (
                <div
                    className={`fixed bottom-4 right-4 px-4 py-2 rounded shadow-lg ${successMessageType === 'delete' ? 'bg-red-600' : 'bg-green-500'
                        } text-white`}
                >
                    {successMessage}
                </div>
            )}
        </div>
    );
};

export default VisitorsList;